import {
  assertCalendarEventCohortInstance,
  assertCalendarEventEngagementAggregateInfo,
  CalendarEventInfo,
} from "components/weekCalendar";
import {
  CalendarEventCohortInstancePopoverBody,
  CalendarEventEngagementAggregateCalendarEventPopoverBody,
} from "./components";
import { CalendarEventPopoverProps as Props } from "./types";

export const CalendarEventPopover = ({
  eventInfo,
  ...props
}: Props<CalendarEventInfo>) => {
  if (assertCalendarEventCohortInstance(eventInfo)) {
    return (
      <CalendarEventCohortInstancePopoverBody
        eventInfo={eventInfo}
        {...props}
      />
    );
  } else if (assertCalendarEventEngagementAggregateInfo(eventInfo)) {
    return (
      <CalendarEventEngagementAggregateCalendarEventPopoverBody
        eventInfo={eventInfo}
        {...props}
      />
    );
  } else {
    return null;
  }
};
