import { IANAtzName, LocalizedWeekday, WeekdayNumber } from "@utils/dateTime";
import clsx from "clsx";
import { CalendarEventInfo } from "components/weekCalendar";
import { Popover } from "components/weekCalendar/components/Popover";
import { getDateData } from "../../utils";
import { WEEK_COL_START_CLASSES } from "../constants";
import CalendarEventDetails from "./components/CalendarEventDetails";
import { CalendarEventPopover } from "./components/CalendarEventPopover";

type Props = {
  eventInfo: CalendarEventInfo;
  localizedWeekdays: LocalizedWeekday[];
  focusedWeekday: WeekdayNumber;
  locale: string;
  mode24Hour: boolean;
  viewingTimeZone: IANAtzName;
  refetch?: () => void;
};

export const CalendarEvent = ({
  eventInfo,
  localizedWeekdays,
  focusedWeekday,
  locale,
  mode24Hour,
  viewingTimeZone,
  refetch,
}: Props) => {
  const startInfo = getDateData(eventInfo, viewingTimeZone);
  const startGridRow = Math.floor(startInfo.minutesElapsedInDay / 5) + 2;
  const gridSpan = Math.max(Math.ceil(eventInfo.durationMinutes / 5), 3);

  return (
    <li
      className={clsx(
        "relative mt-px min-h-[50px]",
        startInfo.weekdayNumber !== focusedWeekday && "hidden",
        WEEK_COL_START_CLASSES[startInfo.weekdayNumber],
        "lg:flex"
      )}
      style={{ gridRow: `${startGridRow} / span ${gridSpan}` }}
    >
      <Popover
        placement="top-start"
        render={() => (
          <CalendarEventPopover
            eventInfo={eventInfo}
            localizedWeekdays={localizedWeekdays}
            locale={locale}
            mode24Hour={mode24Hour}
            viewingTimeZone={viewingTimeZone}
            refetch={refetch}
          />
        )}
      >
        <div
          className={clsx(
            "group absolute inset-1 flex flex-col hover:z-20 cursor-pointer overflow-y-auto rounded-lg p-2 text-xs leading-5",
            `${eventInfo.eventColor?.bg} ${eventInfo.eventColor?.bgHover}`
          )}
        >
          <CalendarEventDetails
            eventInfo={eventInfo}
            startInfo={startInfo}
            mode24Hour={mode24Hour}
            locale={locale}
          />
        </div>
      </Popover>
    </li>
  );
};
