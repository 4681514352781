import { gql, useMutation } from "@apollo/client";
import {
  CancelEventsMutation,
  CancelEventsMutationVariables,
  ExDateStatus,
} from "@generated/graphql";
import {
  triggerErrorToast,
  triggerSuccessToast,
  triggerWarningToast,
} from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import pluralize from "pluralize";

const CANCEL_EVENTS_MUTATION = gql`
  mutation CancelEvents($exDateInput: [ExDateInput!]!) {
    addExDates(exDateInput: $exDateInput) {
      cohortEventId
      status
      startDateTime
      cohortId
      cohortName
    }
  }
`;

type Props = {
  cancellableEvents: CalendarEventCohortInstanceInfo[];
  onSuccess?: () => void;
  refetch?: () => void;
};

export function useCancelEvents({
  cancellableEvents,
  onSuccess,
  refetch,
}: Props) {
  const [cancelEvents] = useMutation<
    CancelEventsMutation,
    CancelEventsMutationVariables
  >(CANCEL_EVENTS_MUTATION, {
    variables: {
      exDateInput: cancellableEvents
        .filter(({ cohortEventId }) => cohortEventId !== null)
        .map(({ cohortEventId, isoStartFloatingDateTime }) => ({
          cohortEventId: cohortEventId ?? "",
          isoStartFloatingDateTime,
        })),
    },
    refetchQueries: ["CohortsScheduleCalendarGetEventInstances"],
    onError: (error) => {
      refetch?.();
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: `We weren't able to cancel ${pluralize(
          "event",
          cancellableEvents.length,
          true
        )}.`,
        log: error,
      });
    },
    onCompleted: ({ addExDates }) => {
      onSuccess && onSuccess();
      refetch?.();
      if (addExDates.length === 0) {
        triggerErrorToast({ message: "Could not cancel event(s)." });
        return;
      }

      const problematicResults = addExDates.filter(
        ({ status }) => status !== ExDateStatus.SuccessExcluded
      );

      if (problematicResults.length === 0) {
        triggerSuccessToast({
          message: `${pluralize(
            "Event",
            addExDates.length,
            true
          )} successfully cancelled!`,
        });
      } else if (problematicResults.length < addExDates.length) {
        triggerWarningToast({
          message: `Failed to cancel ${pluralize(
            "event",
            problematicResults.length,
            true
          )} of ${pluralize("event", addExDates.length, true)}!`,
        });
      } else {
        triggerErrorToast({
          message: `Failed to cancel ${pluralize(
            "event",
            problematicResults.length,
            true
          )}!`,
        });
      }
    },
  });

  return cancelEvents;
}
