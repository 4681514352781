import { Menu, Transition } from "@headlessui/react";
import { VideoCameraIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import { Link } from "components/shared";
import { EventColor } from "components/weekCalendar";
import { Fragment } from "react";
import { DropdownOption } from "../types";
import { NoMeetingLinkProvided } from "./NoMeetingLinkProvided";

type Props = {
  roomLinkOptions: DropdownOption[];
  eventColor: EventColor;
  small?: boolean;
};

export const RoomLinksDropdown = ({
  roomLinkOptions,
  eventColor,
  small = false,
}: Props) => (
  <div
    className={`relative inline-flex rounded-md ${small ? "h-6" : "shadow-md"}`}
  >
    {roomLinkOptions.length > 0 ? (
      <Link
        href={roomLinkOptions[0].link}
        className={clsx(
          "rounded-l-md text-sm font-medium",
          "focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500",
          "relative inline-flex items-center w-full",
          `${eventColor.text} ${eventColor.bg} ${eventColor.bgHover}`,
          small
            ? `p-1 border ${eventColor.border} border-r-0`
            : "p-2 focus:z-10 z-10"
        )}
        target={"_blank"}
      >
        <div className="flex relative justify-start whitespace-nowrap">
          <VideoCameraIcon className="mr-1 h-5 w-5" aria-hidden="true" />
          {!small && <span>{roomLinkOptions[0].label}</span>}
        </div>
      </Link>
    ) : (
      <NoMeetingLinkProvided small={small} />
    )}

    {roomLinkOptions.length > 1 && (
      <>
        <div
          className={`shrink-0 w-0.5 h-auto rounded-sm ${eventColor.accent}`}
        />

        <Menu as="div" className="-ml-px relative block">
          <Menu.Button
            className={clsx(
              "relative inline-flex items-center h-full rounded-r-md",
              "focus:outline-none focus:ring-1",
              "focus:ring-blue-500 focus:border-blue-500",
              `${eventColor.text} ${eventColor.bg} ${eventColor.bgHover}`,
              small ? `p-1 border ${eventColor.border} border-l-0` : "p-2"
            )}
          >
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={clsx(
                "rounded-l-md origin-top-right absolute right-0 z-[400] min-w-[170px] mt-1 rounded-md",
                "shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
                `${eventColor.text} ${eventColor.bg}`
              )}
            >
              {roomLinkOptions.slice(1).map((option) => (
                <div className={`${eventColor.bgHover}`} key={option.label}>
                  <Menu.Item>
                    <span>
                      <Link
                        href={option.link}
                        className="px-2 py-2 w-full text-sm font-medium block"
                        target={"_blank"}
                      >
                        <div className="flex justify-start whitespace-nowrap">
                          <VideoCameraIcon
                            className="mr-1 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>{option.label}</span>
                        </div>
                      </Link>
                    </span>
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    )}
  </div>
);
