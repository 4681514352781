import { CohortEventInstanceStatus } from "@generated/graphql";
import { Popover } from "@headlessui/react";
import { LocalizedWeekday, WeekdayNumber } from "@utils/dateTime";
import clsx from "clsx";
import {
  PopoverButton,
  getPopoverPanelOrientationRules,
} from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import pluralize from "pluralize";
import React, { useMemo } from "react";
import { TbCalendarEvent, TbCalendarX } from "react-icons/tb";
import {
  CancelAllCalendarEventsButton,
  UndoCancelAllCalendarEventsButton,
} from "../CalendarEvents/components/OverlapCalendarEvents";
import { isCancellableEvent } from "../CalendarEvents/utils";
import { CalendarEventNavCancelEventsTable } from "./components/CalendarEventNavCancelEventsTable";

type Props = {
  eventInfos: CalendarEventCohortInstanceInfo[];
  localizedWeekday: LocalizedWeekday;
  currentDay?: boolean;
  mobileMode?: boolean;
  refetch?: () => void;
  buttonClassName?: string;
};

export const CalendarEventNavCancelButton = ({
  eventInfos,
  localizedWeekday,
  mobileMode = false,
  refetch,
  currentDay,
  buttonClassName,
}: Props) => {
  const allUndo = eventInfos.every(
    (event) =>
      event.status === CohortEventInstanceStatus.Cancelled ||
      !isCancellableEvent(event)
  );

  return (
    <PopoverButton
      buttonClassName={clsx(
        buttonClassName,
        currentDay ? "text-red-200" : allUndo ? "text-blue-500" : "text-red-500"
      )}
      customPopoverButtonFunc={() =>
        allUndo ? (
          <TbCalendarEvent className="w-4 h-4" />
        ) : (
          <TbCalendarX className="w-4 h-4" />
        )
      }
      label={`Cancel events on ${localizedWeekday.longWeekday}, ${localizedWeekday.longMonth} ${localizedWeekday.day}`}
    >
      <CalendarEventNavCancelButtonContent
        eventInfos={eventInfos}
        localizedWeekday={localizedWeekday}
        mobileMode={mobileMode}
        refetch={refetch}
      />
    </PopoverButton>
  );
};

type CalendarEventNavCancelButtonContentProps = Exclude<
  Props,
  "buttonClassName"
>;

/**
 * Separating the content requires forwarding the ref to the Popover.Panel. We're
 * bothering to separate the content because we want to memoize the eventInfos
 * only when the popover is open. This saves us from having to recompute
 * constantly while still allowing the "now" time to be updated.
 */
const CalendarEventNavCancelButtonContent = React.forwardRef<
  HTMLDivElement,
  CalendarEventNavCancelButtonContentProps
>(({ eventInfos, localizedWeekday, mobileMode, refetch }, ref) => {
  const { orientationClassNames } =
    getPopoverPanelOrientationRules("BOTTOM-START");

  const { cancellableEvents, pendingEvents, pastEvents, undoCancelledEvents } =
    useMemo(() => {
      const sortedEventInfos = eventInfos.sort((a, b) => {
        if (a.startDateTime < b.startDateTime) return -1;
        if (a.startDateTime > b.startDateTime) return 1;
        if (a.durationMinutes < b.durationMinutes) return -1;
        if (a.durationMinutes > b.durationMinutes) return 1;
        if (a.cohortName < b.cohortName) return -1;
        if (a.cohortName > b.cohortName) return 1;
        return 0;
      });
      const cancellableEvents: CalendarEventCohortInstanceInfo[] = [];
      const pendingEvents: CalendarEventCohortInstanceInfo[] = [];
      const pastEvents: CalendarEventCohortInstanceInfo[] = [];
      const undoCancelledEvents: CalendarEventCohortInstanceInfo[] = [];

      const now = new Date();

      for (const event of sortedEventInfos) {
        if (event.status === CohortEventInstanceStatus.Cancelled) {
          undoCancelledEvents.push(event);
          continue;
        }
        if (isCancellableEvent(event)) {
          cancellableEvents.push(event);
        } else if (event.startDateTime > now) {
          pendingEvents.push(event);
        } else {
          pastEvents.push(event);
        }
      }

      return {
        cancellableEvents,
        pendingEvents,
        pastEvents,
        undoCancelledEvents,
      };
    }, [eventInfos]);

  return (
    <Popover.Panel
      ref={ref}
      className={clsx(
        orientationClassNames,
        POPOVER_OFFSET[localizedWeekday.weekday][
          mobileMode ? "mobile" : "desktop"
        ],
        "absolute min-w-max z-10 drop-shadow-lg p-5 bg-white border-t-2 border-gray-100 rounded-md"
      )}
    >
      <div className="flex flex-col gap-y-2 max-w-[350px] text-gray-800 text-sm text-center">
        <div className="flex flex-col gap-y-2 mb-3">
          <CalendarEventNavCancelEventsTable
            title={pluralize(
              "Cancellable Event",
              cancellableEvents.length,
              true
            )}
            tooltipContent={`You may cancel ${
              cancellableEvents.length === 1
                ? "this cancellable event"
                : "these cancellable events"
            }`}
            titleClassName="text-blue-600"
            events={cancellableEvents}
            refetch={refetch}
          />

          <CalendarEventNavCancelEventsTable
            title={pluralize("Pending Event", pendingEvents.length, true)}
            titleClassName="text-orange-400"
            tooltipContent={`It is too late to cancel ${
              pendingEvents.length === 1 ? "this event" : "these events"
            }, ${
              pendingEvents.length === 1 ? "it is" : "they are"
            } starting soon.`}
            events={pendingEvents}
          />

          <CalendarEventNavCancelEventsTable
            title={pluralize("Past Event", pastEvents.length, true)}
            titleClassName="text-red-600"
            tooltipContent={`It is too late to cancel ${
              pastEvents.length === 1 ? "this event" : "these events"
            }, ${
              pastEvents.length === 1 ? "it has" : "they have"
            } already started.`}
            events={pastEvents}
          />

          <CalendarEventNavCancelEventsTable
            title={pluralize(
              "Cancelled Events",
              undoCancelledEvents.length,
              true
            )}
            titleClassName="text-green-600"
            tooltipContent={`${
              undoCancelledEvents.length === 1
                ? "This event has"
                : "These events have"
            } been cancelled. Undo the ${
              undoCancelledEvents.length === 1
                ? "cancellation"
                : "cancellations"
            } that have not already occurred.`}
            events={undoCancelledEvents}
            refetch={refetch}
          />
        </div>

        <CancelAllCalendarEventsButton
          cancellableEventInstanceInfos={cancellableEvents}
          refetch={refetch}
        />

        <UndoCancelAllCalendarEventsButton
          undoCancelledEvents={undoCancelledEvents}
          refetch={refetch}
        />
      </div>
    </Popover.Panel>
  );
});

CalendarEventNavCancelButtonContent.displayName =
  "CalendarEventNavCancelButtonContent";

/**
 * The offset of the popover from the button. Unlike CalendarEventPopover, this
 * component make automatic calculations to make sure that it doesn't get cut off.
 * A future to-do might be to utilize CohortScheduleCalendar/components/Popover.tsx,
 * which uses a special `useFloating()` hook.
 */
const POPOVER_OFFSET: {
  [key in WeekdayNumber]: { desktop: string; mobile: string };
} = {
  0: { desktop: "-translate-x-1/3", mobile: "" },
  1: { desktop: "-translate-x-1/2", mobile: "-translate-x-1/4" },
  2: { desktop: "-translate-x-1/2", mobile: "-translate-x-1/3" },
  3: { desktop: "-translate-x-1/2", mobile: "-translate-x-1/2" },
  4: { desktop: "-translate-x-2/3", mobile: "-translate-x-2/3" },
  5: { desktop: "-translate-x-3/4", mobile: "-translate-x-3/4" },
  6: { desktop: "-translate-x-full", mobile: "-translate-x-full" },
};
