import { LocalizedWeekday } from "@utils/dateTime";

type MonthDisplayProps = {
  firstDay: LocalizedWeekday;
  lastDay: LocalizedWeekday;
  className?: string;
};

export const MonthDisplay = ({
  firstDay,
  lastDay,
  className = "",
}: MonthDisplayProps) => (
  <span
    className={`text-lg font-semibold text-gray-900 capitalize ${className}`}
  >
    {firstDay.month !== lastDay.month ? firstDay.shortMonth : ""}
    {firstDay.year !== lastDay.year ? " " + String(firstDay.year) : ""}
    {firstDay.month !== lastDay.month ? " – " : ""}
    {firstDay.month !== lastDay.month
      ? lastDay.shortMonth
      : lastDay.longMonth}{" "}
    {lastDay.year}
  </span>
);
