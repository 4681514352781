import clsx from "clsx";
import { Tooltip } from "components/shared";
import { EventColor } from "components/weekCalendar";
import { LuVideoOff } from "react-icons/lu";
import { MdError } from "react-icons/md";

type Props = { small?: boolean; colors?: EventColor };

export const NoMeetingLinkProvided = ({ small = false, colors }: Props) =>
  small ? (
    <Tooltip content={<span>No meeting link provided</span>}>
      <div
        className={clsx(
          "flex justify-start items-center relative border rounded-md px-[21px] py-1 h-6 cursor-not-allowed",
          "border-red-500 text-red-500 hover:border-red-700 hover:bg-red-100 hover:text-red-700",
          colors?.bgHover,
          colors?.bg
        )}
      >
        <LuVideoOff className="ml-1 h-4 w-4" aria-hidden="true" />
      </div>
    </Tooltip>
  ) : (
    <div className="flex justify-start items-center whitespace-nowrap text-normal font-semibold text-red-500">
      <MdError className="mr-1 h-5 w-5" aria-hidden="true" />
      <span>No meeting link provided</span>
    </div>
  );
