import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Tooltip } from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import { CalendarEventNavCancelTableRow } from "./CalendarEventNavCancelTableRow";

type Props = {
  title: string;
  titleClassName: string;
  tooltipContent?: string;
  events: CalendarEventCohortInstanceInfo[];
  refetch?: () => void;
};

export const CalendarEventNavCancelEventsTable = ({
  title,
  titleClassName,
  tooltipContent,
  events,
  refetch,
}: Props) =>
  events.length > 0 ? (
    <div className="flex flex-col gap-y-1.5 text-gray-800 text-left">
      <div className="flex flex-row gap-x-2">
        <h2 className={clsx("text-md font-semibold", titleClassName)}>
          {title}
        </h2>
        {tooltipContent && (
          <Tooltip className="cursor-help" content={tooltipContent} />
        )}
      </div>
      <div
        className={clsx(
          "text-xs text-gray-500 max-h-[250px] overflow-y-auto",
          getScrollbarStyle("gray"),
          "border-gray-200 border rounded-md"
        )}
      >
        <table className="table-fixed text-xs text-gray-500">
          <tbody>
            {events.map((event, index) => (
              <CalendarEventNavCancelTableRow
                key={event.cohortEventId}
                event={event}
                index={index}
                refetch={refetch}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
