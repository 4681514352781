import { IANAtzName } from "@utils/dateTime";
import clsx from "clsx";
import { Button } from "components/shared";
import {
  assertCalendarEventCohortInstance,
  assertCalendarEventEngagementAggregateInfo,
  CalendarEventInfo,
} from "components/weekCalendar";
import pluralize from "pluralize";
import { useState } from "react";
import { CalendarEngagementAggregateEventsSummaryModal } from "./components/CalendarEngagementAggregateEventsSummaryModal";
import { CalendarEventsActionsModal } from "./components/CalendarEventsActionsModal";

type Props = {
  eventInfos: CalendarEventInfo[];
  showViewPanel: boolean;
  refetch?: () => void;
  locale: string;
  mode24Hour: boolean;
  viewingTimeZone: IANAtzName;
};

export const CalendarEventsViewPanel = ({
  showViewPanel,
  eventInfos,
  refetch,
  locale,
  mode24Hour,
  viewingTimeZone,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const allCohortEventInstances = eventInfos.every(
    assertCalendarEventCohortInstance
  );
  const allEngagementAggregateEvents = eventInfos.every(
    assertCalendarEventEngagementAggregateInfo
  );

  return (
    <>
      <div
        className={clsx(
          "bulkEditPanel inset-1 rounded-md text-sm text-white bg-gray-200 cursor-pointer",
          showViewPanel && !showModal
            ? "bulkEditPanelExpanded z-[2]"
            : "bulkEditPanelCollapsed z-[0]"
        )}
      >
        <Button
          onClick={() => setShowModal(true)}
          className={clsx(
            "relative flex flex-center font-medium h-7 rounded-md bg-blue-500 hover:bg-blue-600 w-full"
          )}
        >
          <div className="flex flex-center gap-x-2 flex-wrap leading-none">
            View{" "}
            {pluralize(
              allCohortEventInstances ? "Event" : "Engagement Group",
              eventInfos.length,
              true
            )}
          </div>
        </Button>
      </div>

      {allCohortEventInstances && (
        <CalendarEventsActionsModal
          show={showModal}
          eventInstanceInfos={eventInfos}
          refetch={refetch}
          setShowModal={setShowModal}
        />
      )}
      {allEngagementAggregateEvents && (
        <CalendarEngagementAggregateEventsSummaryModal
          show={showModal}
          engagementAggregateEventInfos={eventInfos}
          setShowModal={setShowModal}
          locale={locale}
          mode24Hour={mode24Hour}
          viewingTimeZone={viewingTimeZone}
        />
      )}
    </>
  );
};
