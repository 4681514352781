import { gql } from "@apollo/client";
import {
  AssignmentRelationRole,
  Cohort,
  WeekCalendarHeader_HolidayFragment,
} from "@generated/graphql";
import { LocalizedWeekday } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import clsx from "clsx";
import { ICalendarGeneratorPopover } from "components/iCalendar/ICalendarGeneratorPopover";
import {
  ICalendarInputMT,
  ICalendarInputTT,
} from "components/iCalendar/ICalendarInputEditors";
import { buildCalendarNameFuncForUserRelations } from "components/iCalendar/iCalendarUtils";
import { Button, Icon } from "components/shared";
import { getShowMiniCalendar } from "components/shared/NavigationCalendars/utils";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import { useAuth } from "contexts/AuthProvider";
import { useLayout } from "contexts/LayoutProvider";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { CancelWeekEventsModal } from "./components/CancelWeekEventsModal";
import { MonthDisplay } from "./components/MonthDisplay";
import { ScheduleNavigationCalendars } from "./components/ScheduleNavigationCalendar";

WeekCalendarHeader.fragments = {
  holiday: gql`
    fragment WeekCalendarHeader_Holiday on Holiday {
      id
      ...ScheduleNavigationCalendars_Holiday
    }
    ${ScheduleNavigationCalendars.fragments.holiday}
  `,
};

type Props = {
  viewDate: Date;
  endBound?: Date;
  startBound?: Date;
  cohortIds: Cohort["id"][];
  externalControls?: React.ReactNode;
  localizedWeekdays: LocalizedWeekday[];
  holidays: WeekCalendarHeader_HolidayFragment[];
  eventInstanceInfos: CalendarEventCohortInstanceInfo[];
  setViewDate: (date: Date) => void;
};

export function WeekCalendarHeader({
  viewDate,
  holidays,
  endBound,
  cohortIds,
  startBound,
  externalControls,
  localizedWeekdays,
  eventInstanceInfos,
  setViewDate,
}: Props) {
  const router = useRouter();
  const { screenWidth } = useLayout();
  const calendarsWidth = screenWidth - 655;
  const { isAdmin, isMentor, user } = useAuth();
  const isMiniCalendar = getShowMiniCalendar(calendarsWidth);
  const [showCancelWeekModal, setShowCancelWeekModal] = useState(false);

  const noEvents = eventInstanceInfos.length === 0;
  const eventsHaveStarted =
    !noEvents && new Date() > eventInstanceInfos[0].startDateTime;

  const isTeacherMySchedulePage =
    user && router.pathname === Routes.mySchedule.path();

  const exportCalendarButton = isTeacherMySchedulePage ? (
    <ICalendarGeneratorPopover
      openOrientation="BOTTOM-START"
      buttonLabel="Export your Calendar"
      buttonClassName="text-sm text-gray-700 font-medium bg-white hover:bg-gray-50 -mt-1"
      inputEditor={
        isMentor ? ICalendarInputMT(user.id) : ICalendarInputTT(user.id)
      }
      calendarNameFunc={buildCalendarNameFuncForUserRelations(
        user.id,
        user.fullName
      )}
      initialBuildICalendarInput={{
        users: [
          {
            userId: user.id,
            relations: isMentor
              ? [AssignmentRelationRole.EngagementMentor]
              : [
                  AssignmentRelationRole.CohortPrimaryTeacher,
                  AssignmentRelationRole.CohortSubstituteTeacher,
                ],
          },
        ],
      }}
    />
  ) : null;

  const renderNavigationCalendars = (
    <div className={clsx("flex", isMiniCalendar ? "h-fit flex-1" : "h-full")}>
      <ScheduleNavigationCalendars
        viewDate={viewDate}
        holidays={holidays}
        endBound={endBound}
        cohortIds={cohortIds}
        startBound={startBound}
        events={eventInstanceInfos}
        calendarsWidth={calendarsWidth}
        setViewDate={setViewDate}
      />
    </div>
  );

  return (
    <header className="relative z-50 flex flex-none items-center justify-between gap-x-2 h-auto pb-4 lg:pl-2">
      <div
        className={clsx(
          "flex flex-col gap-2",
          isMiniCalendar ? "w-full " : "w-fit "
        )}
      >
        <div
          className={clsx(
            isMiniCalendar
              ? "flex w-full justify-between"
              : "flex flex-col gap-y-3"
          )}
        >
          <MonthDisplay
            className="text-lg lg:text-[35px] leading-8 lg:font-semibold lg:text-gray-900 col-span-2"
            firstDay={localizedWeekdays[0]}
            lastDay={localizedWeekdays[6]}
          />

          {isTeacherMySchedulePage && exportCalendarButton}

          {isAdmin && (
            <Button
              height="sm"
              className="flex gap-x-2 w-fit col-span-2"
              disabled={noEvents || eventsHaveStarted}
              onClick={() => setShowCancelWeekModal(true)}
            >
              <Icon icon="cancelCalendar" color="text-white" size={4} />
              Cancel Week Sessions
            </Button>
          )}
        </div>

        <div className={clsx("flex items-end justify-between")}>
          {externalControls && (
            <div className="flex flex-col gap-y-1 sm:col-span-1 col-span-2">
              {externalControls}
            </div>
          )}

          {isMiniCalendar && renderNavigationCalendars}
        </div>
      </div>

      {!isMiniCalendar && renderNavigationCalendars}

      <CancelWeekEventsModal
        show={showCancelWeekModal}
        events={eventInstanceInfos}
        firstDay={localizedWeekdays[0]}
        lastDay={localizedWeekdays[6]}
        cancelModal={() => setShowCancelWeekModal(false)}
      />
    </header>
  );
}
