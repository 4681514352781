import { Modal } from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import { useAuth } from "contexts/AuthProvider";
import { format } from "date-fns";
import { noop } from "lodash";
import pluralize from "pluralize";
import { useRef, useState } from "react";
import {
  getCancellableEvents,
  getDurationMinutes,
  getEarliestEvent,
  getLatestEvent,
} from "../../../../../../utils";
import { CalendarEventsActionsTable } from "./components/CalendarEventsActionsTable";
import { CancelAllCalendarEventsButton } from "./components/CancelAllCalendarEventsButton";

type Props = {
  show: boolean;
  setShowModal: (show: boolean) => void;
  eventInstanceInfos: CalendarEventCohortInstanceInfo[];
  refetch?: () => void;
};

export const CalendarEventsActionsModal = ({
  show,
  eventInstanceInfos,
  refetch,
  setShowModal,
}: Props) => {
  const { isAdmin } = useAuth();
  const focusRef = useRef<HTMLDivElement | null>(null);
  const defaultCancel = Array(eventInstanceInfos.length).fill(false);
  const [checked, setChecked] = useState<boolean[]>(defaultCancel);
  const startEvent = getEarliestEvent(eventInstanceInfos);
  const endEvent = getLatestEvent(eventInstanceInfos);
  const duration = getDurationMinutes(startEvent, endEvent);
  const start = format(startEvent.startDateTime, "EE, MMM d @ h:mm a");
  const endTime = format(endEvent.endDateTime, "h:mm a");
  const title = `${start}-${endTime} (${duration} min)`;
  const cancellableEventInstanceInfos =
    getCancellableEvents(eventInstanceInfos);
  const hasCancellableEvents = cancellableEventInstanceInfos.length > 0;
  const selectedCancellableEventsCount = checked.filter((v) => v).length;

  const allButtonText = hasCancellableEvents
    ? `Cancel All ${pluralize(
        "Cancellable Event",
        cancellableEventInstanceInfos.length,
        true
      )}`
    : "No Cancellable Events";

  const selectedButtonText = `Cancel ${pluralize(
    "Selected Event",
    selectedCancellableEventsCount,
    true
  )}`;

  return (
    <Modal
      title={title}
      initialFocus={focusRef}
      icon={<Modal.Icon icon="calendarEvents" />}
      width="large"
      onDismissClick={() => setShowModal(false)}
      show={show}
      onClose={noop}
    >
      <div className="flex flex-col gap-y-4 mt-4" ref={focusRef}>
        <CalendarEventsActionsTable
          eventInstanceInfos={eventInstanceInfos}
          checked={checked}
          setChecked={setChecked}
          hasCancellableEvents={hasCancellableEvents}
        />

        {isAdmin && (
          <div className="flex w-full gap-3 flex-center mb-2 mt-3">
            <CancelAllCalendarEventsButton
              cancellableEventInstanceInfos={cancellableEventInstanceInfos}
              className="h-8 whitespace-nowrap"
              buttonText={allButtonText}
              onSuccess={() => setShowModal(false)}
              refetch={refetch}
            />
            <CancelAllCalendarEventsButton
              cancellableEventInstanceInfos={eventInstanceInfos.filter(
                (_, i) => checked[i]
              )}
              className="h-8 whitespace-nowrap"
              buttonText={selectedButtonText}
              onSuccess={() => setShowModal(false)}
              refetch={refetch}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
