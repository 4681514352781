import { gql } from "@apollo/client";
import {
  MeetingLink_Meeting_CohortFragment,
  VideoProvider,
} from "@generated/graphql";
import { RoomType } from "@utils/roomUrls";
import { Routes } from "@utils/routes";
import { assertUnreachable } from "@utils/types";
import { EventColor } from "components/weekCalendar";
import { NoMeetingLinkProvided } from "./components/NoMeetingLinkProvided";
import { RoomLinksDropdown } from "./components/RoomLinksDropdown";
import { DropdownOption } from "./types";

MeetingLink.fragments = {
  meetingCohort: gql`
    fragment MeetingLink_Meeting_Cohort on Cohort {
      meetingRoom
      publicReferenceId
      engagement {
        videoProvider
      }
    }
  `,
};

type Props = {
  cohort: MeetingLink_Meeting_CohortFragment;
  eventColor: EventColor;
  small?: boolean;
};

export function MeetingLink({
  cohort: {
    meetingRoom,
    engagement: { videoProvider },
    publicReferenceId,
  },
  eventColor,
  small = false,
}: Props) {
  if (!meetingRoom)
    return <NoMeetingLinkProvided small={small} colors={eventColor} />;

  const roomLinkOptions: DropdownOption[] = [];

  const makeRoomLink = (roomType: RoomType) =>
    Routes.meetingRoomPage.href(publicReferenceId, roomType);

  switch (videoProvider) {
    case VideoProvider.MicrosoftTeams:
      roomLinkOptions.push({
        label: "Join Teams Meeting",
        link: makeRoomLink("all"),
      });
      break;
    case VideoProvider.Zoom:
      roomLinkOptions.push(
        {
          label: "Join Room as Host",
          link: makeRoomLink("host"),
        },
        {
          label: "Join Room as Student",
          link: makeRoomLink("student"),
        }
      );
      break;
    case VideoProvider.Manual:
      roomLinkOptions.push({
        label: "Join Manual Meeting",
        link: makeRoomLink("all"),
      });
      break;
    case VideoProvider.GoogleMeets:
      roomLinkOptions.push({
        label: "Join GMeet Meeting",
        link: makeRoomLink("all"),
      });
      break;
    default:
      assertUnreachable(videoProvider, "videoProvider");
  }

  return (
    <RoomLinksDropdown
      roomLinkOptions={roomLinkOptions}
      eventColor={eventColor}
      small={small}
    />
  );
}
