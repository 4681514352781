import { EventColor } from "components/weekCalendar";

/**
 * Need this array defined because we're using the `sm:` prefix, cannot just
 * define the `gridColumnStart: weekdayIndex + 1`. Tailwind doesn't work that way.
 */
export const WEEK_COL_START_CLASSES = [
  "lg:col-start-1", // Sunday...
  "lg:col-start-2",
  "lg:col-start-3",
  "lg:col-start-4", // ...Wednesday...
  "lg:col-start-5",
  "lg:col-start-6",
  "lg:col-start-7", // ...Saturday
];

export const EVENT_COLORS: EventColor[] = [
  {
    bg: "bg-green-100",
    bgHover: "hover:bg-green-200",
    text: "text-green-600",
    border: "border-green-600",
    textHover: "group-hover:text-green-700",
    accent: "bg-green-700",
  },
  {
    bg: "bg-yellow-100",
    bgHover: "hover:bg-yellow-200",
    text: "text-yellow-600",
    border: "border-yellow-600",
    textHover: "group-hover:text-yellow-700",
    accent: "bg-yellow-700",
  },
  {
    bg: "bg-teal-100",
    bgHover: "hover:bg-teal-200",
    text: "text-teal-600",
    border: "border-teal-600",
    textHover: "group-hover:text-teal-700",
    accent: "bg-teal-700",
  },
  {
    bg: "bg-blue-100",
    bgHover: "hover:bg-blue-200",
    text: "text-blue-600",
    border: "border-blue-600",
    textHover: "group-hover:text-blue-700",
    accent: "bg-blue-700",
  },
  {
    bg: "bg-emerald-100",
    bgHover: "hover:bg-emerald-200",
    text: "text-emerald-600",
    border: "border-emerald-600",
    textHover: "group-hover:text-emerald-700",
    accent: "bg-emerald-700",
  },
  {
    bg: "bg-orange-100",
    bgHover: "hover:bg-orange-200",
    text: "text-orange-600",
    border: "border-orange-600",
    textHover: "group-hover:text-orange-700",
    accent: "bg-orange-700",
  },
  {
    bg: "bg-blue-100",
    bgHover: "hover:bg-blue-200",
    text: "text-blue-600",
    border: "border-blue-600",
    textHover: "group-hover:text-blue-700",
    accent: "bg-blue-700",
  },
  {
    bg: "bg-fuchsia-100",
    bgHover: "hover:bg-fuchsia-200",
    text: "text-fuchsia-600",
    border: "border-fuchsia-600",
    textHover: "group-hover:text-fuchsia-700",
    accent: "bg-fuchsia-700",
  },
  {
    bg: "bg-pink-100",
    bgHover: "hover:bg-pink-200",
    text: "text-pink-600",
    border: "border-pink-600",
    textHover: "group-hover:text-pink-700",
    accent: "bg-pink-700",
  },
  {
    bg: "bg-amber-100",
    bgHover: "hover:bg-amber-200",
    text: "text-amber-600",
    border: "border-amber-600",
    textHover: "group-hover:text-amber-700",
    accent: "bg-amber-700",
  },
  {
    bg: "bg-slate-100",
    bgHover: "hover:bg-slate-200",
    text: "text-slate-600",
    border: "border-slate-600",
    textHover: "group-hover:text-slate-700",
    accent: "bg-slate-700",
  },
];

export const DEFAULT_EVENT_COLOR: EventColor = {
  bg: "bg-gray-100",
  bgHover: "hover:bg-gray-200",
  text: "text-gray-600",
  border: "border-gray-600",
  textHover: "group-hover:text-gray-700",
  accent: "bg-gray-700",
};
