import { ISODate } from "@utils/dateTime";
import clsx from "clsx";
import { useAuth } from "contexts/AuthProvider";
import pluralize from "pluralize";
import { CalendarNavData } from "../types";
import { CalendarEventNavCancelButton } from "./CalendarEventNavCancelButton";
import { HolidaysIndicator } from "./Header/components/HolidaysIndicator";

type Props = {
  className: string;
  hideEventCount?: boolean;
  navData: CalendarNavData[];
  currentViewerISODate: ISODate;
  refetch?: () => void;
};

export const DesktopNav = ({
  navData,
  className,
  currentViewerISODate,
  hideEventCount = false,
  refetch,
}: Props) => {
  const { isAdmin } = useAuth();

  return (
    <div
      className={clsx(
        "mr-[9px] grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-4 text-gray-500 gap-y-1",
        className
      )}
    >
      <div className="col-end-1 w-14" />
      {navData.map(({ localizedWeekday, eventInfos }) => {
        const { isoDate, longWeekday, shortWeekday, day } = localizedWeekday;
        const currentDay = currentViewerISODate === isoDate;

        return (
          <div
            key={longWeekday}
            className="flex flex-col items-center justify-start pt-3 text-gray-900"
          >
            <div
              className={clsx(
                "flex flex-col px-2 py-1 w-full pt-[5px] flex-center",
                currentDay && "rounded-md bg-blue-500 text-white"
              )}
            >
              <span className="font-semibold capitalize whitespace-nowrap">
                {shortWeekday} {day}
              </span>

              <div className="flex flex-row flex-wrap gap-x-2 justify-center">
                {!hideEventCount && (
                  <div
                    className={clsx(
                      "text-gray-500 font-normal text-xs",
                      currentDay && "text-white"
                    )}
                  >
                    {pluralize("Event", eventInfos.length, true)}
                  </div>
                )}

                {isAdmin &&
                  currentViewerISODate <= isoDate &&
                  eventInfos.length !== 0 && (
                    <CalendarEventNavCancelButton
                      eventInfos={eventInfos}
                      localizedWeekday={localizedWeekday}
                      refetch={refetch}
                      currentDay={currentDay}
                    />
                  )}
              </div>
            </div>
          </div>
        );
      })}

      <div className="col-end-1 w-14 h-2" />
      {navData.map(({ localizedWeekday, navHolidays }) => (
        <div key={localizedWeekday.isoDate} className="w-full px-0.5 pb-1">
          <HolidaysIndicator navHolidays={navHolidays} />
        </div>
      ))}
    </div>
  );
};
