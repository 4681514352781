import { gql, useMutation } from "@apollo/client";
import {
  CalendarEventUndoCancelButtonMutation,
  CalendarEventUndoCancelButtonMutationVariables,
  CohortEvent,
  UndoCancellationStatus,
} from "@generated/graphql";
import { assertUnreachable } from "@utils/types";
import {
  triggerErrorToast,
  triggerSuccessToast,
  triggerWarningToast,
} from "components/shared";
import { TbCalendarEvent } from "react-icons/tb";

const CALENDAR_EVENT_UNDO_CANCEL_MUTATION = gql`
  mutation CalendarEventUndoCancelButton(
    $cohortEventId: ID!
    $isoStartFloatingDateTime: String!
  ) {
    undoEventCancellation(
      undoCancellationInput: [
        {
          cohortEventId: $cohortEventId
          isoStartFloatingDateTime: $isoStartFloatingDateTime
        }
      ]
    ) {
      cohortEventId
      status
      startDateTime
      cohortId
      cohortName
    }
  }
`;

type Props = {
  cohortEventId: CohortEvent["id"];
  isoStartFloatingDateTime: string;
  refetch?: () => void;
};

export function UndoCalendarEventCancellationButton({
  cohortEventId,
  isoStartFloatingDateTime,
  refetch,
}: Props) {
  const [undoEventCancellations] = useMutation<
    CalendarEventUndoCancelButtonMutation,
    CalendarEventUndoCancelButtonMutationVariables
  >(CALENDAR_EVENT_UNDO_CANCEL_MUTATION, {
    variables: {
      cohortEventId,
      isoStartFloatingDateTime,
    },
    onError: (error) => {
      refetch?.();
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: "We weren't able to undo the cancellation of this event.",
        log: error,
      });
    },
    onCompleted: ({ undoEventCancellation }) => {
      refetch?.();

      const { status, cohortName, cohortEventId, startDateTime } =
        undoEventCancellation[0];

      switch (status) {
        case UndoCancellationStatus.Success:
          triggerSuccessToast({
            message: `The cancellation for this event was undone!`,
            sub: new Date(startDateTime).toLocaleString(),
          });
          break;
        case UndoCancellationStatus.FailureEventInstanceInPast:
          triggerWarningToast({
            message: `Event for ${cohortName} could not be undone as it is in the past!`,
            sub: new Date(startDateTime).toLocaleString(),
          });
          break;
        case UndoCancellationStatus.FailureCohortEventNotFound:
        case UndoCancellationStatus.FailureEventInstanceNotCancelled:
          triggerErrorToast({
            message: "Could not undo the cancellation of this event.",
          });
          console.error(`cohortEventId: ${cohortEventId}, status: ${status}`);
          break;
        default:
          assertUnreachable(status);
      }
    },
  });

  return (
    <button
      className="text-xs text-blue-500 font-medium underline-offset-2 hover:underline flex flex-row gap-x-0.5 tracking-tight"
      onClick={() => undoEventCancellations()}
    >
      <TbCalendarEvent className="w-4 h-4" />
      <span>Undo</span>
    </button>
  );
}
