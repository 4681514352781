import { IANAtzName, LocalizedWeekday, WeekdayNumber } from "@utils/dateTime";
import clsx from "clsx";
import {
  CalendarEventInfo,
  CalendarEventPopover,
} from "components/weekCalendar";
import { Popover } from "components/weekCalendar/components/Popover";
import { useState } from "react";
import {
  getDateData,
  getDurationMinutes,
  getEarliestEvent,
  getHeight,
  getLatestEvent,
  getTop,
} from "../../utils";
import CalendarEventDetails from "../CalendarEvent/components/CalendarEventDetails";
import { WEEK_COL_START_CLASSES } from "../constants";
import { CalendarEventsViewPanel } from "./components/CalendarEventsViewPanel";

export type Props = {
  eventInfos: CalendarEventInfo[];
  localizedWeekdays: LocalizedWeekday[];
  focusedWeekday: WeekdayNumber;
  locale: string;
  mode24Hour: boolean;
  viewingTimeZone: IANAtzName;
  refetch?: () => void;
};

export const OverlapCalendarEvents = ({
  eventInfos,
  locale,
  mode24Hour,
  focusedWeekday,
  viewingTimeZone,
  localizedWeekdays,
  refetch,
}: Props) => {
  const [currEvent, setCurrEvent] = useState<CalendarEventInfo>(eventInfos[0]);
  const [showViewPanel, setShowViewPanel] = useState<boolean>(false);

  const earliestStart = getEarliestEvent(eventInfos);
  const latestEnd = getLatestEvent(eventInfos);
  const durationMinutes = getDurationMinutes(earliestStart, latestEnd);
  const gridSpan = Math.max(Math.ceil(durationMinutes / 5), 3);
  const earliestInfo = getDateData(earliestStart, viewingTimeZone);
  const startGridRow = Math.floor(earliestInfo.minutesElapsedInDay / 5) + 2;

  return (
    <li
      onMouseOver={() => setShowViewPanel(true)}
      onMouseLeave={() => setShowViewPanel(false)}
      className={clsx(
        "relative mt-px min-h-[50px]",
        earliestInfo.weekdayNumber !== focusedWeekday && "hidden",
        WEEK_COL_START_CLASSES[earliestInfo.weekdayNumber],
        "lg:flex"
      )}
      style={{ gridRow: `${startGridRow} / span ${gridSpan}` }}
    >
      <div
        className={clsx(
          `group h-full cursor-pointer absolute inset-1 flex flex-col rounded-lg text-xs p-[3px]`,
          `font-semibold hover:bg-gray-200 z-[8] hover:z-20 bg-gray-100 opacity-1 text-gray-500`
        )}
      >
        {/* Hoverable Event Columns */}
        <div className={`flex h-full gap-[2px] w-full`}>
          {eventInfos.map((eventInfo) => (
            <Popover
              key={eventInfo.key}
              placement="top-start"
              render={() => (
                <CalendarEventPopover
                  eventInfo={eventInfo}
                  localizedWeekdays={localizedWeekdays}
                  locale={locale}
                  mode24Hour={mode24Hour}
                  viewingTimeZone={viewingTimeZone}
                  refetch={refetch}
                />
              )}
            >
              <div
                onMouseOver={() => setCurrEvent(eventInfo)}
                className={clsx(
                  "flex-1 relative rounded-md border border-white",
                  eventInfo.eventColor?.bg,
                  eventInfo.eventColor?.bgHover
                )}
                style={{
                  height: getHeight(eventInfo, durationMinutes),
                  top: getTop(eventInfo, earliestStart, durationMinutes),
                }}
              />
            </Popover>
          ))}
        </div>

        <CalendarEventDetails
          eventInfo={currEvent}
          startInfo={getDateData(currEvent, viewingTimeZone)}
          mode24Hour={mode24Hour}
          locale={locale}
          isBulkEvent
        />
      </div>

      <CalendarEventsViewPanel
        showViewPanel={showViewPanel}
        eventInfos={eventInfos}
        refetch={refetch}
        locale={locale}
        mode24Hour={mode24Hour}
        viewingTimeZone={viewingTimeZone}
      />
    </li>
  );
};
