export class IndexMap<T> {
  private objects: T[];
  private indexMap: Record<string, number>;

  constructor(objects: T[], getIdFunc: (obj: T) => string | number) {
    this.objects = objects;
    this.indexMap = this.buildIndexMap(getIdFunc);
  }

  private buildIndexMap(
    getIdFunc: (obj: T) => string | number
  ): Record<string, number> {
    return this.objects.reduce<Record<string, number>>((map, obj, index) => {
      const key = getIdFunc(obj);
      map[String(key)] = index;
      return map;
    }, {});
  }

  public get(id: string | number): T | undefined {
    const index = this.indexMap[String(id)];
    return index !== undefined ? this.objects[index] : undefined;
  }

  public has(id: string | number): boolean {
    return this.indexMap[String(id)] !== undefined;
  }

  public keys(): string[] {
    return Object.keys(this.indexMap);
  }
}
