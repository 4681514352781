import { assertUnreachable } from "@utils/types";
import { CalendarDisplayMode } from "./types";

export const getDisplayRules = (mode: CalendarDisplayMode) => {
  switch (mode) {
    case CalendarDisplayMode.Responsive:
      return {
        mobileNav: "grid lg:hidden",
        desktopNav: "hidden lg:grid",
        verticalLines: "hidden lg:grid",
        orderedList: "grid-cols-1 lg:grid-cols-7",
      };
    case CalendarDisplayMode.AlwaysMobile:
      return {
        mobileNav: "grid",
        desktopNav: "hidden",
        verticalLines: "hidden",
        orderedList:
          "grid-cols-1 *:col-span-full *:col-start-1 *:lg:col-start-1",
      };
    case CalendarDisplayMode.AlwaysDesktop:
      return {
        mobileNav: "hidden",
        desktopNav: "grid",
        verticalLines: "grid",
        orderedList: "grid-cols-7",
      };
    default:
      assertUnreachable(mode);
  }
};
