import { localizedTime } from "@utils/dateTime";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { CalendarEventInfo } from "components/weekCalendar";
import { DateData } from "../../../utils";

type Props = {
  eventInfo: CalendarEventInfo;
  startInfo: DateData;
  isBulkEvent?: boolean;
  locale: string;
  mode24Hour: boolean;
};

const CalendarEventDetails = ({
  eventInfo,
  startInfo,
  isBulkEvent = false,
  mode24Hour,
  locale,
}: Props) => (
  <div
    className={clsx(
      "group absolute inset-1 flex flex-col hover:z-20 overflow-x-hidden rounded-lg p-2 text-xs leading-5",
      isBulkEvent
        ? "bg-opacity-20 pointer-events-none overflow-y-hidden"
        : `${getScrollbarStyle()} overflow-y-auto`,
      `${eventInfo.eventColor?.bg} ${eventInfo.eventColor?.bgHover}`
    )}
  >
    <p
      className={`flex flex-row justify-between ${eventInfo.eventColor?.text} ${eventInfo.eventColor?.textHover}`}
    >
      <time dateTime={`${startInfo.isoDate}T${startInfo.time}`}>
        {localizedTime(startInfo.time, mode24Hour, locale)}
      </time>
      {eventInfo.charm && (
        <span className="w-4 h-4 shrink-0">{eventInfo.charm}</span>
      )}
    </p>
    <p className={`font-semibold leading-tight ${eventInfo.eventColor?.text}`}>
      {eventInfo.title || "Untitled Event"}
    </p>
    {eventInfo.details && (
      <p className={`font-normal leading-tight ${eventInfo.eventColor?.text}`}>
        {eventInfo.details}
      </p>
    )}
  </div>
);

export default CalendarEventDetails;
