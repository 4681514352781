import {
  AssignmentRelationRole,
  BuildICalendarInput,
  User,
} from "@generated/graphql";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import toArray from "lodash/toArray";
import Image from "next/legacy/image";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

export type NoticeDetails = React.FC;

type ICalUrl = string;

type Notice = {
  label: string;
  details: NoticeDetails;
};

export type CalendarService = {
  platform: string;
  icon: JSX.Element;
  url: string;
  title: string;
  shortTitle: string;
  subtitle?: string;
  notices: Notice[];
  disabled: boolean;
};

export function buildCalendarServices(
  iCalUrl: ICalUrl,
  iconClassName: string,
  userAgentFlags?: {
    isMobileOnly?: boolean;
  }
): CalendarService[] {
  // For each service maintain a list of notices.
  const appleNotices: Notice[] = [];
  const googleNotices: Notice[] = [];
  const outlookNotices: Notice[] = [];
  const outlookcomNotices: Notice[] = [];
  const microsoft365Notices: Notice[] = [];

  // Create and add Mobile warnings.
  if (userAgentFlags?.isMobileOnly) {
    const outlookMobileNotice: Notice = {
      label: "Importing to Outlook.com/Microsoft 365 on a smart phone",
      details: () => (
        <div className="flex flex-col gap-2">
          <p>
            While using a smart phone you may run into an issue when trying to
            add your calendar to Outlook.com/Microsoft 365.
          </p>
          <p>
            If you see a blank page after clicking the link try using the{" "}
            <strong>&quot;Request Desktop Site&quot;</strong> feature of your
            browser.
          </p>
          <p>
            On iOS Safari find and press the <span className="text-xs">A</span>A
            button in the address bar to open the menu. There you&apos;ll find
            the &quot;Request Desktop Site&quot; option.
          </p>
          <p>
            On Android find and press the
            <DotsVerticalIcon className="w-4 h-4 inline" /> button to open the
            menu. There you&apos;ll find the &quot;Request Desktop Site&quot;
            option.
          </p>
        </div>
      ),
    };
    outlookcomNotices.push(outlookMobileNotice);
    microsoft365Notices.push(outlookMobileNotice);
  }

  return [
    {
      platform: "apple",
      icon: <FaApple className={`text-gray-700 ${iconClassName}`} />,
      url: buildAppleUrl(iCalUrl),
      title: "Apple Calendar",
      shortTitle: "Apple",
      subtitle: "(iOS/MacOS)",
      disabled: false,
      notices: appleNotices,
    },
    {
      platform: "google",
      icon: <FcGoogle className={iconClassName} />,
      url: buildGoogleCalendarUrl(iCalUrl),
      title: "Google Calendar",
      shortTitle: "Google",
      subtitle: "(Online)",
      disabled: false,
      notices: googleNotices,
    },
    {
      platform: "microsoft365",
      icon: <Microsoft365Logo className={iconClassName} />,
      url: buildMicrosoft365Url(iCalUrl),
      title: "Microsoft 365",
      shortTitle: "Microsoft 365",
      subtitle: "(Online)",
      disabled: false,
      notices: microsoft365Notices,
    },
    {
      platform: "outlookcom",
      icon: <OutlookComLogo className={iconClassName} />,
      url: buildOutlookComUrl(iCalUrl),
      title: "Outlook",
      shortTitle: "Outlook",
      subtitle: "(Online)",
      disabled: false,
      notices: outlookcomNotices,
    },
    {
      platform: "outlook",
      icon: <OutlookLogo className={iconClassName} />,
      url: buildOutlookUrl(iCalUrl),
      title: "Outlook",
      shortTitle: "Outlook",
      subtitle: "(Windows)",
      disabled: false,
      notices: outlookNotices,
    },
  ];
}

export type DisplayNotice = {
  details: NoticeDetails;
  label: string;
  indicator: number;
  serviceIndexes: number[];
};

/**
 * Takes in your CalendarServices[] and builds an array of DisplayNotice objects.
 * These DisplayNotices can be displayed in the UI that show the different
 * calendar services. Often used in conjunction with buildNoticeIndicators().
 * @param calendarServices
 * @returns
 */
export function buildDisplayNotices(
  calendarServices: CalendarService[]
): DisplayNotice[] {
  const displayNotices: DisplayNotice[] = [];

  // Build the display notices data. Don't ask.
  reduce(
    toArray(
      groupBy(
        calendarServices
          .map(({ notices }, serviceIndex) => ({
            notices,
            serviceIndex,
          }))
          .filter(({ notices }) => notices.length != 0)
          .map(
            ({ notices, serviceIndex }) =>
              groupBy(
                notices.map((notice) => ({ notice, serviceIndex })),
                "serviceIndex"
              )[serviceIndex]
          )
          .flat(),
        "notice.label"
      )
    ),
    (result, value, index) => {
      result.push({
        details: value[0].notice.details,
        label: value[0].notice.label,
        serviceIndexes: value.map(({ serviceIndex }) => serviceIndex),
        indicator: index + 1,
      });
      return result;
    },
    displayNotices
  );

  return displayNotices;
}

/**
 * Builds the list of indicators for your service by its serviceIndex (the index
 * of the service as it exists in the array returned by buildCalendarServices()).
 * The intention is to use this list of numbers as citation-like indicators.
 *
 * You know the citation numbers on Wikipedia? "So and so died on April 1st,
 * 1850. [3] And had three children. [4]". Those [numbers] refer to details
 * elsewhere on the page. If your service has two notices, "[1, 2]", you'll
 * be able to use the notice's indicator value to match these pieces of
 * information that you can display elsewhere. Or not. Up to you.
 * @param displayNotices
 * @param serviceIndex
 * @returns
 */
export function buildNoticeIndicators(
  displayNotices: DisplayNotice[],
  serviceIndex: number
): number[] {
  return displayNotices
    .filter((notice) => notice.serviceIndexes.includes(serviceIndex))
    .map((notice) => notice.indicator);
}

export function buildICalUrl(origin: string, referenceId: string): string {
  return `${origin}/api/ical/${referenceId}`;
}

export function convertToWebCalURI(iCalUrl: ICalUrl): string {
  return iCalUrl.replace(/^.+:\/\//, "webcal://");
}

export function convertHttpsToHttp(url: string): string {
  return url.replace(/^https:\/\//, "http://");
}

export function buildAppleUrl(iCalUrl: ICalUrl): string {
  return convertToWebCalURI(iCalUrl);
}

export function buildGoogleCalendarUrl(iCalUrl: ICalUrl): string {
  return `https://calendar.google.com/calendar/u/0/r?pli=1&cid=${encodeURIComponent(
    convertHttpsToHttp(iCalUrl) // Don't know why, but Google requires http://.
  )}`;
}

export function buildMicrosoft365Url(
  iCalUrl: ICalUrl,
  calendarName = "Tutored by Teachers"
): string {
  return `https://outlook.office.com/calendar/0/addfromweb?mkt=en-001&name=${encodeURIComponent(
    calendarName
  )}&url=${encodeURIComponent(iCalUrl)}`;
}

export function buildOutlookUrl(iCalUrl: ICalUrl): string {
  return convertToWebCalURI(iCalUrl);
}

export function buildOutlookComUrl(
  iCalUrl: ICalUrl,
  calendarName = "Tutored by Teachers"
): string {
  return `https://outlook.live.com/calendar/0/addfromweb?mkt=en-001&name=${calendarName}&url=${encodeURIComponent(
    iCalUrl
  )}`;
}

export const officialDocumentationUrls: { href: string; label: string }[] = [
  {
    href: "https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac",
    label: "Apple Calendar (MacOS)",
  },
  {
    href: "https://support.apple.com/guide/iphone/use-multiple-calendars-iph3d1110d4/ios",
    label: "Apple Calendar (iOS)",
  },
  {
    href: "https://support.google.com/calendar/answer/37100",
    label: "Google Calendar",
  },
  {
    href: "https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379",
    label: "Outlook (Windows)",
  },
  {
    href: "https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-on-the-web-503ffaf6-7b86-44fe-8dd6-8099d95f38df",
    label: "Outlook/Microsoft 365 (Online)",
  },
];

type OutlookLogoProps = {
  className?: string;
};

export function Microsoft365Logo({ className }: OutlookLogoProps) {
  return (
    <div className={`relative ${className}`}>
      <Image src={"/logos/microsoft-365-logo.svg"} alt="" layout="fill" />
    </div>
  );
}

export function OutlookLogo({ className }: OutlookLogoProps) {
  return (
    <div className={`relative ${className}`}>
      <Image src={"/logos/outlook-logo-yellow.svg"} alt="" layout="fill" />
    </div>
  );
}

export function OutlookComLogo({ className }: OutlookLogoProps) {
  return (
    <div className={`relative ${className}`}>
      <Image src={"/logos/outlook-logo-blue.svg"} alt="" layout="fill" />
    </div>
  );
}

export const assignmentRelationRoleLabels = {
  [AssignmentRelationRole.CohortPrimaryTeacher]: {
    label: "Primary Teacher",
    description: "See Cohorts where you are the primary teacher.",
  },
  [AssignmentRelationRole.CohortSubstituteTeacher]: {
    label: "Substitute Teacher",
    description: "See Cohorts where you are a substitute teacher.",
  },
  [AssignmentRelationRole.EngagementMentor]: {
    label: "Engagement Mentor",
    description: "See Cohorts where you are a Mentor of its parent Engagement.",
  },
  [AssignmentRelationRole.EngagementSubstitute]: {
    label: "Engagement Substitute",
    description:
      "See Cohorts where you are a Substitute of its parent Engagement.",
  },
};

/**
 * Builds a function that can be used to generate a calendar name based on the
 * user's relations, using the provided user ID and full name.
 * @param userId
 * @param userFullName
 * @returns A function that can be used to generate a calendar name using a
 * `BuildICalendarInput` as input.
 */
export function buildCalendarNameFuncForUserRelations(
  userId: User["id"],
  userFullName: User["fullName"]
) {
  return (input: BuildICalendarInput) => {
    const fallbackName = `${userFullName}'s Calendar`;

    if (input.users == null) {
      return fallbackName;
    }

    const relations =
      input?.users?.find((r) => r.userId === userId)?.relations ?? [];

    if (relations.length === 0) {
      return fallbackName;
    }

    return `${userFullName}'s Calendar (with ${relations
      .sort()
      .map((r) => assignmentRelationRoleLabels[r].label)
      .join(" + ")} Cohorts)`;
  };
}
