import { CohortEventInstanceStatus } from "@generated/graphql";
import clsx from "clsx";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import {
  CalendarEventCancelButton,
  UndoCalendarEventCancellationButton,
} from "../../CalendarEvents/components/CalendarEvent";
import { isCancellableEvent } from "../../CalendarEvents/utils";

type Props = {
  event: CalendarEventCohortInstanceInfo;
  index: number;
  refetch?: () => void;
};

export const CalendarEventNavCancelTableRow = ({
  event,
  index,
  refetch,
}: Props) => (
  <tr className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
    <td className="px-2 py-1 border-r border-gray-300 tabular-nums whitespace-nowrap">
      {event.startDateTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}
    </td>
    <td
      className={clsx(
        "px-2 py-1 w-full border-r border-gray-300 tabular-nums whitespace-nowrap",
        event.status === CohortEventInstanceStatus.Cancelled && "line-through"
      )}
    >
      {event.cohortName}
    </td>
    <td className="flex flex-center py-1 w-[70px]">
      {isCancellableEvent(event) && event.cohortEventId !== null ? (
        event.status === CohortEventInstanceStatus.Cancelled ? (
          <UndoCalendarEventCancellationButton
            cohortEventId={event.cohortEventId}
            isoStartFloatingDateTime={event.isoStartFloatingDateTime}
            refetch={refetch}
          />
        ) : (
          <CalendarEventCancelButton
            cohortEventId={event.cohortEventId}
            isoStartFloatingDateTime={event.isoStartFloatingDateTime}
            refetch={refetch}
          />
        )
      ) : null}
    </td>
  </tr>
);
