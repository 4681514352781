import {
  COHORT_SESSION_BUFFER_MS,
  IANAtzName,
  MINUTE_MS,
  WeekdayNumber,
  getDateInfo,
} from "@utils/dateTime";
import {
  CalendarEventCohortInstanceInfo,
  CalendarEventInfo,
} from "components/weekCalendar";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { EVENT_COLORS } from "./components/constants";

export type DateData = {
  isoDate: string;
  weekdayNumber: WeekdayNumber;
  time: string;
  minutesElapsedInDay: number;
};

export const mergeOverlappingEvents = (
  eventInfos: CalendarEventInfo[]
): CalendarEventInfo[][] => {
  const isOverlap = (
    range1: CalendarEventInfo,
    range2: CalendarEventInfo
  ): boolean =>
    range1.startDateTime.getTime() + 5 * MINUTE_MS <=
      range2.endDateTime.getTime() &&
    range2.startDateTime.getTime() + 5 * MINUTE_MS <=
      range1.endDateTime.getTime();

  const sortedEvents = eventInfos
    .slice()
    .sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime());

  const mergedEventRanges: CalendarEventInfo[][] = [];

  for (const event of sortedEvents) {
    let addedToGroup = false;
    for (const group of mergedEventRanges) {
      if (group.some((existingRange) => isOverlap(existingRange, event))) {
        group.push(event);
        addedToGroup = true;
        break;
      }
    }

    if (!addedToGroup) {
      mergedEventRanges.push([event]);
    } else {
      // Check for additional merging
      let index = mergedEventRanges.length - 1;
      while (index > 0) {
        if (
          isOverlap(
            mergedEventRanges[index - 1][0],
            mergedEventRanges[index][0]
          )
        ) {
          mergedEventRanges[index - 1] = mergedEventRanges[index - 1].concat(
            mergedEventRanges[index]
          );
          mergedEventRanges.splice(index, 1);
        }
        index--;
      }
    }
  }

  // Sort events within each group by start time, then duration
  for (const group of mergedEventRanges) {
    group.sort((a, b) => {
      const startDiff = a.startDateTime.getTime() - b.startDateTime.getTime();
      return startDiff === 0
        ? a.durationMinutes - b.durationMinutes
        : startDiff;
    });
  }

  return mergedEventRanges;
};

export const getEarliestEvent = (eventInfos: CalendarEventInfo[]) =>
  eventInfos.reduce((earliest: CalendarEventInfo | null, current) => {
    return !earliest ||
      current.startDateTime.getTime() < earliest.startDateTime?.getTime()
      ? current
      : earliest;
  });

export const getLatestEvent = (eventInfos: CalendarEventInfo[]) =>
  eventInfos.reduce((latest: CalendarEventInfo, current) => {
    return !latest ||
      current.endDateTime.getTime() > latest.endDateTime?.getTime()
      ? current
      : latest;
  });

export const getEventColors = (groupNumbers: number[]) =>
  groupNumbers.map(
    (groupNumber) => EVENT_COLORS[groupNumber % EVENT_COLORS.length]
  );

export const getDurationMinutes = (
  startEventInfo: CalendarEventInfo,
  endEventInfo: CalendarEventInfo
) =>
  (endEventInfo?.endDateTime.getTime() -
    startEventInfo?.startDateTime.getTime()) /
  MINUTE_MS;

export const getDateData = (
  eventInfo: CalendarEventInfo,
  tz: IANAtzName,
  end?: boolean
) =>
  getDateInfo(
    utcToZonedTime(end ? eventInfo?.endDateTime : eventInfo?.startDateTime, tz)
  );

export const getHeight = (eventInfo: CalendarEventInfo, duration: number) =>
  `${Math.round(
    ((eventInfo.endDateTime.getTime() - eventInfo.startDateTime.getTime()) /
      MINUTE_MS /
      duration) *
      100
  )}%`;

export const getTop = (
  eventInfo: CalendarEventInfo,
  earliest: CalendarEventInfo,
  duration: number
) =>
  `${
    ((eventInfo.startDateTime.getTime() - earliest.startDateTime.getTime()) /
      MINUTE_MS /
      duration) *
    100
  }%`;

export const getCancellableEvents = (
  eventInstanceInfos: CalendarEventCohortInstanceInfo[]
) => {
  const now = new Date();
  return eventInstanceInfos.filter(
    (event) => +event.startDateTime > +now + COHORT_SESSION_BUFFER_MS
  );
};

export const isCancellableEvent = (
  eventInstanceInfo: CalendarEventCohortInstanceInfo
) => {
  const now = new Date();
  return +eventInstanceInfo.startDateTime > +now + COHORT_SESSION_BUFFER_MS;
};

export const getRowColor = (rowsLength: number, index: number) => {
  const rowsLengthIsOdd = rowsLength % 2 === 1;
  if (rowsLengthIsOdd && index % 2 === 1) return "bg-white";
  if (rowsLengthIsOdd && index % 2 === 0) return "bg-gray-100";
  if (!rowsLengthIsOdd && index % 2 === 1) return "bg-gray-100";
  if (!rowsLengthIsOdd && index % 2 === 0) return "bg-white";
};
