import clsx from "clsx";
import { Checkbox } from "components/shared";
import {
  CalendarEventCohortInstanceInfo,
  DEFAULT_EVENT_COLOR,
} from "components/weekCalendar";
import { MeetingLink } from "components/weekCalendar/components/MeetingLink";
import { useAuth } from "contexts/AuthProvider";
import { cloneDeep } from "lodash";
import { TbCalendarX } from "react-icons/tb";
import { getRowColor, isCancellableEvent } from "../../../../../../../utils";

type Props = {
  checked: boolean[];
  eventInstanceInfos: CalendarEventCohortInstanceInfo[];
  hasCancellableEvents: boolean;
  setChecked: (checked: boolean[]) => void;
};

export const CalendarEventsActionsTable = ({
  eventInstanceInfos,
  checked,
  hasCancellableEvents,
  setChecked,
}: Props) => {
  const { isAdmin } = useAuth();
  let actionsColWidth = hasCancellableEvents ? "w-[178px]" : "w-[148px]";
  actionsColWidth = isAdmin ? actionsColWidth : "w-[80px]";

  const updateChecked = (index: number) => {
    const newChecked = cloneDeep(checked);
    newChecked[index] = !checked[index];
    setChecked(newChecked);
  };

  const renderCancelPanel = (i: number, id: string, isCancellable: boolean) => (
    <div
      className={clsx(
        "relative flex flex-center w-auto border border-gray-300 rounded-md gap-1 h-6",
        isCancellable
          ? "hover:border-red-500 hover:bg-red-100 cursor-pointer text-red-500 p-1"
          : "cursor-not-allowed border-0",
        checked[i] && "border-red-500 bg-red-100"
      )}
      onClick={() => updateChecked(i)}
    >
      {hasCancellableEvents && isCancellable && (
        <Checkbox
          checked={checked[i]}
          disabled={!isCancellable}
          className="h-4 w-4 pointer-events-none"
          inputClassName={`${checked[i] && "text-red-500 focus:ring-red-500 "}`}
        />
      )}
      <div className="relative flex gap-x-0.5 tracking-tight text-xs font-medium">
        <TbCalendarX className="w-4 h-4" />
        <span>Cancel</span>
      </div>
    </div>
  );

  return (
    <div className="border-gray-200 w-full border rounded-md">
      <table className="table-fixed w-full text-xs text-gray-500">
        <thead className="bg-gray-400 text-white h-7">
          <tr>
            <th className="w-auto p-2 border-r border-gray-300">Cohort</th>
            <th className="w-auto p-2 border-r border-gray-300">Details</th>
            <th className="w-auto p-2 border-r border-gray-300">
              Primary Teacher
            </th>
            <th className={`p-2 ${actionsColWidth}`}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {eventInstanceInfos.map(
            ({ eventColor = DEFAULT_EVENT_COLOR, ...event }, index) => (
              <tr
                key={index}
                className={`${getRowColor(eventInstanceInfos.length, index)}`}
              >
                <td className="w-auto p-2 border-r border-gray-300">
                  {event.cohortName}
                </td>
                <td className="w-auto p-2 border-r border-gray-300">
                  {event.details ?? ""}
                </td>
                <td className="w-auto p-2 border-r border-gray-300">
                  {(event.content &&
                    event.content({})?.props.filteredStaffAssignments[0]?.user
                      ?.fullName) ??
                    ""}
                </td>
                <td className={`px-2 py-1 ${actionsColWidth}`}>
                  <div className="relative flex flex-center gap-2">
                    {isAdmin &&
                      event.cohortEventId !== null &&
                      renderCancelPanel(
                        index,
                        event.cohortEventId,
                        isCancellableEvent(event)
                      )}
                    {event.content && (
                      <div className="relative flex flex-center">
                        <MeetingLink
                          cohort={event.content({})?.props.cohort}
                          eventColor={eventColor}
                          small
                        />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};
